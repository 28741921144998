import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import socketService from '@/services/websocket.service'
import vue3GoogleLogin from 'vue3-google-login'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(socketService)
app.use(vue3GoogleLogin, {
  clientId: '1053721506688-pdrqsgvagqsan80esh01lteeu5j96dp0.apps.googleusercontent.com'
})
app.mount('#app')