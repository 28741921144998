<template>
  <h1>MAD MAX</h1>
  <el-main>
    <el-container class="column">
      <DistributionList v-show="buildState == null"/>
      <el-alert v-if="!sshPhoneAvailable" title="Mad Max decryption is currently down" type="warning" show-icon :closable="false" class="down-alert">
        <p>Please use the <a href="https://armconverter.com/decryptedappstore/us" target="_blank">decrypted app store</a> to get a dycrpted IPA and upload below</p>
      </el-alert>
      <el-radio-group v-model="buildMethod" class="method-toggle" v-show="buildState == null" @change="$store.commit('setBuildMethod', $event)">
        <el-radio-button label="Decrypt" value="decrypt" :disabled="sshPhoneAvailable != 'available'"/>
        <el-radio-button label="Build" value="build" :disabled="sshPhoneAvailable != 'available'"/>
        <el-radio-button label="Upload" value="upload"/>
      </el-radio-group>
      <div v-show="buildState == null">
        <UploadIPA v-if="buildMethod == 'upload'"/>
        <AppSearch v-else/>
      </div>
      <BuildLogs v-show="buildState != null"/>
      <SecurityScan v-if="scanResults"/>
    </el-container>
    <el-radio-group label="Skip to Step" v-model="skipToStep" class="method-toggle" v-show="buildState == null && userData.email == 'reade.lobdill@contentsquare.com'" @change="$store.commit('setSkipToStep', $event)">
      <el-radio-button label="None" value="null" />
      <el-radio-button label="Decrypt" value="decrypt"/>
      <el-radio-button label="Build" value="build"/>
    </el-radio-group>
  </el-main>
</template>

<script>
import { mapState } from 'vuex'
import {
  DistributionList,
  AppSearch,
  UploadIPA,
  BuildLogs,
  SecurityScan
} from '@/components'
import socketService from '@/services/websocket.service'

export default {
  name: 'Home',
  components: {
    DistributionList,
    AppSearch,
    UploadIPA,
    BuildLogs,
    SecurityScan
  },
  computed: {
    ...mapState([
      'userData',
      'buildState',
      'scanResults',
      'buildMethod',
      'sshPhoneAvailable',
      'userData',
      'skipToStep'
    ])
  },
  created(){
    socketService.io.emit('get-phone-status');
  }
}
</script>

<style lang="scss">
.el-main{
  padding: 5px !important;
  .el-container{
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.12),0 0 6px rgba(0,0,0,0.04);

    &.column {
      padding: 0px;
      max-width: 600px;
      margin: 0 auto;
      flex-direction: column;
    }
    .down-alert .el-alert__content{
      margin: 0 auto;
    }
    .method-toggle{
      margin: 25px auto;
    }
  }

  .el-upload-dragger{
    width: 300px !important;
  }

  .el-radio-group {
    margin-top: 15px;
  }
}

</style>
