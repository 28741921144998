<template>
<el-header>Search for an App</el-header>
<div class="app-search-box">
  <div class="input-box">
    <!-- <el-radio-group v-model="deviceType">
      <el-radio-button label="iOS"></el-radio-button>
      <el-radio-button label="Android"></el-radio-button>
    </el-radio-group> -->
    <div class="flag-container">
      <country-flag :country='country'/>
      <el-select v-model="country" @change="apps = []">
        <el-option
          v-for="country in availableCountries"
          :key="country"
          :label="country"
          :value="country">
          <country-flag :country='country'/>
          <span>{{country}}</span>
        </el-option>
      </el-select>
    </div>

    <el-form :model="form" :rules="rules" ref="form" @submit.native.prevent="_searchApps">
      <el-form-item prop="appName">
        <el-input placeholder="Search Here" v-model="form.appName"/>
      </el-form-item>
      <el-form-item class="button">
        <el-button type="primary" @click="_searchApps" :disabled="loading" v-loading="loading">
          <el-icon style="vertical-align: middle">
            <Search />
          </el-icon>
        </el-button>
      </el-form-item>
    </el-form>


  </div>
  <el-table
    v-if="apps.length"
    :data="apps"
    highlight-current-row
    @row-click="_setApp">

    <el-table-column
      type="type"
      width="100">
      <template #default="scope">
          <el-image
            :src="scope.row.artworkUrl100">
          </el-image>
      </template>
    </el-table-column>

    <el-table-column
      prop="trackName"
      label="Name"
      width="175">
    </el-table-column>

    <el-table-column
      v-if="userData.email == 'reade.lobdill@contentsquare.com'"
      prop="bundleId"
      label="Name"
      width="175">
    </el-table-column>

    <el-table-column
      type="type"
      label="Size"
      width="75">
      <template #default="scope">
          <span>{{`${Math.round(scope.row.fileSizeBytes/1e+6)}MB`}}</span>
      </template>
    </el-table-column>

  </el-table>
</div>
<el-affix :offset="50" position="bottom" v-if="app">
  <el-container class="build">
    <el-image :src="app.artworkUrl60" fit="contain"></el-image>
    <span>{{app.trackName}}</span>
    <el-button :type="buildMethod == 'build' ? 'success' : 'warning'" @click="_buildApp">
      <el-icon>
        <MagicStick />
      </el-icon>
      <span>{{buildMethod == "build" ? "Build" : "Get Decrypted IPA"}}</span>
    </el-button>  
  </el-container>
</el-affix>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapState } from 'vuex'
import socketService from '@/services/websocket.service'
import CountryFlag from 'vue-country-flag-next'

export default defineComponent ({
  components: {
    CountryFlag
  },
  data() {
    return {
      loading: false,
      deviceType: 'iOS',
      form: {
        appName: '',
      },
      availableCountries: [
        'US',
        'GB',
        'FR',
        'DE',
        'NL',
        'AU',
        'IN',
        'IT',
        'BE',
        'SG',
        'ID',
        'PH',
        'JP'
      ],
      country: 'US',
      apps: [],
      rules: {
        appName: [
          {required: true, message: 'I mean, enter something.', trigger: 'blur'}
        ]
      }
    };
  },
  computed: {
    ...mapState([
      'distributionList',
      'app',
      'buildParams',
      'buildMethod',
      'userData',
      'skipToStep'
    ])
  },
  methods: {
    _searchApps(){
      this.$store.commit('setApp', null)
      this.apps = [];
      this.$refs.form.validate(async (valid) => {
        if (valid && !this.loading) {
          this.loading = true;
          try {
            // https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/iTuneSearchAPI/Searching.html#//apple_ref/doc/uid/TP40017632-CH5-SW1
            // add Google play search
            let response = await fetch(`https://itunes.apple.com/search?&media=software&term=${this.form.appName}&country=${this.country}`)
            let { results } = await response.json();
            // TOOD filter by free
            this.apps = results;
          } catch (e) {
            console.warn(e)
          }
          this.loading = false;
        }
      })
    },
    _setApp(app){
      this.$store.commit('setApp', app)
    },
    _buildApp(){
      this.$store.commit('setBuildParams', {
        bundleId: this.app.bundleId,
        distributionList: this.distributionList.join(','),
        countryCode: this.country
      });
      this.$store.dispatch('startDecryptAndBuild')
      socketService.io.emit('download-ipa', this.buildParams, this.buildMethod, this.skipToStep);

      this.apps = [];
      this.form.appName = '';
    }
  }
})
</script>

<style lang="scss">
.app-search-box{
  margin: 0 auto;
  padding: 0px;
  .el-radio-group{
    margin-bottom: 20px;
    width: 250px;
    .el-radio-button__inner{
      width: 125px;
    }
  }


  .input-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 5px;
    max-width: 550px;
    margin: 0 auto 20px auto;

    .flag-container{
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .flag{
        margin-top: -9px;
      }

      .el-select{
        width: 125px;
      }
    }

    .el-form{
      display: flex;
      margin: 0 auto;
      justify-content: space-around;
      align-items: center;

      .el-form-item{
        margin-bottom: 0;
        button{
          width: 75px;
          margin: 0;
          padding: 0 5px;
        }
      }

      .el-input {
        width: 250px;
        padding-right: 5px;

        &__inner{
          max-width: 400px;
        }
      }
    }
    @media screen and (max-width: 400px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
      max-width: 350px;
      .el-form {
        .el-input {
          width: 250px;
          padding-right: 5px;

          &__inner{
            max-width: 400px;
          }
        }
      }
    }

  }

  .el-table{
    margin: 0 auto;

    &__header{
      margin: 0 auto;
    }
  }

  .loading-progress{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loading-progress .el-icon-download {
    font-size: 50px;
  }
}
.el-popper{
  .el-select-dropdown__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100px;
    padding: 10px;
    justify-content: space-around;

    .flag{
      margin-top: -5px;
    }

    span{
      font-family: 'Helvetica Neue',Helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei','微软雅黑',Arial,sans-serif;
      font-weight: 300;
      font-size: 14px;
    }
  }
}
.el-affix{ 
  .el-container.build{
    z-index: 1000;
    margin: 0 auto 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    max-width: 600px;
    padding: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;

    .el-button{
      font-size: 18px;
      font-weight: bold;
      height: 60px;
    }
  }
}


</style>
