<template>
  <el-container class="security-scan">
    <el-collapse>
      <el-collapse-item title="Security Scan">        
        <el-progress type="dashboard" :stroke-width="16" :percentage="scanResults.appsec.security_score" :color="scanResultsColors" ></el-progress>
        <h2>Security Score: {{scanResults.appsec.security_score}}/100</h2>
        <el-collapse accordion>
          <el-collapse-item title="High" v-if="scanResults.appsec.high.length">
            <div v-for="high of scanResults.appsec.high">
              <h3>{{high.section}} – {{high.title}}</h3>
              <p>{{high.description}}</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Warning" v-if="scanResults.appsec.warning.length">
            <div v-for="warning of scanResults.appsec.warning">
              <h3>{{warning.section}} – {{warning.title}}</h3>
              <p>{{warning.description}}</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Info" v-if="scanResults.appsec.info.length">
            <div v-for="info of scanResults.appsec.info">
              <h3>{{info.section}} – {{info.title}}</h3>
              <p>{{info.description}}</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Secure" v-if="scanResults.appsec.secure.length">
            <div v-for="secure of scanResults.appsec.secure">
              <h3>{{secure.section}} – {{secure.title}}</h3>
              <p>{{secure.description}}</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Hotspot" v-if="scanResults.appsec.hotspot.length">
            <div v-for="hotspot of scanResults.appsec.hotspot">
              <h3>{{hotspot.section}} – {{hotspot.title}}</h3>
              <p>{{hotspot.description}}</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>
  </el-container>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent ({
  data() {
    return {
      scanResultsColors: [
        {color: '#f56c6c', percentage: 25},
        {color: '#e6a23c', percentage: 50},
        {color: '#5cb87a', percentage: 75},
        {color: '#1989fa', percentage: 100}
      ]
    };
  },
  computed: {
    ...mapState(['scanResults'])
  },
  methods: {
    _onUploadFilesChange(file, fileList) {
      this.fileList = fileList;
    },
    _submitUpload({ file }){
      this.$store.commit('setBuildState', 'uploading');
      socketService.io.emit('upload-ipa', {
        distributionList: this.distributionList.join(','),
        name: file.name.replace(/[()\s]/g,''), 
        data: file
      })
    }
  }
})
</script>

<style lang="scss">
.el-container.security-scan{
  display: block;
  .el-progress{
    display: flex;
    justify-content: center;
  }
  h2 {
    text-align: center;
  }
  .el-collapse-item__content{
    text-align: left;
  }  
}
</style>
