<template>
  <div class="home">
    <GoogleLogin :callback="onSignInSuccess" prompt auto-login/>
  </div>
</template>

<script>
import { 
  decodeCredential
} from "vue3-google-login"

export default {
  name: 'home',
  methods: {
    onSignInSuccess (response) {
      const userData = decodeCredential(response.credential)
      this.$store.dispatch('logUserIn', userData);
      window._uxa = window._uxa || [];
      window._uxa.push(['trackPageEvent', userData.email.split('@')[0]]);
      window._uxa.push(["trackDynamicVariable", {key: 'user', value: userData.email.split('@')[0]}]);
      heap.identify(userData.email);
      this.$router.push(`/`);
    }
  }
}
</script>

<style lang="scss">
.home{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
