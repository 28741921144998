import io from 'socket.io-client';
import VueSocketIO from 'vue-3-socket.io'
import store from '@/store'

export default new VueSocketIO({
	connection: io({ path: '/ws' }),
	vuex: {
		store,
		actionPrefix: "socket::"
	}
})