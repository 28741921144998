<template>
  <el-header>Upload a decrypted IPA file</el-header>
  <el-upload
    class="upload"
    ref="upload"
    accept=".ipa"
    action="/api/upload-ipa"
    :disabled="buildState == 'uploading'"
    :on-change="_onUploadFilesChange"
    :auto-upload="false"
    :http-request="_submitUpload"
    :file-list="fileList"
    :limit="1"
    drag>
    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    <div class="el-upload__text">
      Drop file here or <em>click to upload</em>
    </div>
    <template #tip>
      <div class="el-upload__tip">
        decrypted .ipa files only
      </div>
    </template>
  </el-upload>
  <el-button v-if="fileList.length" :disable="buildState == 'uploading'" size="large" type="success" @click="$refs.upload.submit()">Upload & Build</el-button>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import socketService from '@/services/websocket.service'

export default defineComponent ({
  data() {
    return {
      fileList: []
    };
  },
  computed: {
    ...mapState([
      'distributionList',
      'buildState'
    ])
  },
  methods: {
    _onUploadFilesChange(file, fileList) {
      this.fileList = fileList;
    },
    _submitUpload({ file }){
      const reader = new FileReader();
      const name = file.name.replace(/[()\s]/g,'');
      const distributionList = this.distributionList.join(',');
      // Read the file in chunks (e.g., 5MB)
      const chunkSize = 2500 * 1024;
      let offset = 0;
      

      reader.onload = function(event) {
        const arrayBuffer = event.target.result;
        console.log("Chunk", offset, file.size)
        // Send the file chunk to server
        socketService.io.emit('upload-ipa-chunk', {
          name: name,
          chunk: arrayBuffer,
          progress: Math.round((offset/file.size * 100)/2),
          distributionList: distributionList,
          isLastChunk: offset > file.size
        })
      };

      function readChunk() {
        const slice = file.slice(offset, offset + chunkSize);
        reader.readAsArrayBuffer(slice);
        
        offset += chunkSize;

        if (offset < file.size) {
          setTimeout(readChunk, 100) // Delay between chunks
        }
      }

      this.$store.commit('setBuildState', 'uploading');
      readChunk();
    },
    _resetBuild(){
      this.$refs.upload.clearFiles();
      this.fileList = [];
      this.$store.dispatch('resetBuild')
    }
  }
})
</script>

<style lang="scss">
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-button{
  margin: 10px;
}
</style>
