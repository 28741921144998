import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import Auth from '../views/Auth.vue'
import CustomAppDemo from '../views/CustomAppDemo.vue'

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/',
    name: 'Mad Max',
    component: CustomAppDemo,
    meta: {requiresAuth: true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

window._uxa = window._uxa || [];
router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth && !store.state.loggedIn) {
    if (to.path !== from.path) window._uxa.push(['trackPageview', '/auth']);
    next('/auth')
  } else {
    window._uxa.push(['trackPageview', to.path]);
    next()
  }
})

export default router
