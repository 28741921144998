import { createStore } from 'vuex'

export default createStore({
  state: {
    loggedIn: false,
    userData: {},
    progress: 0,
    buildState: null,
    logs: [],
    scanResults: null,
    distributionList: [],
    app: null,
    buildParams: null,
    buildMethod: 'upload',
    downloadURL: null,
    sshPhoneAvailable: 'pending',
    skipToStep: null
  },
  mutations: {
    setLoggedIn(state, loggedIn){
      state.loggedIn = loggedIn;
    },
    setUserData(state, userData){
      state.userData = userData;
    },
    setBuildState(state, status){
      state.buildState = status;
    },
    setProgress(state, progress){
      state.progress = progress;
    },
    addLogs(state, logs){
      state.logs = logs;
    },
    resetLogs(state) {
      state.logs = [];
    },
    setScanResults(state, scanResults){
      state.scanResults = scanResults;
    },
    setDistributionList(state, list){
      state.distributionList = list;
    },
    setApp(state, app){
      state.app = app;
    },
    setBuildParams(state, buildParams){
      state.buildParams = buildParams;
    },
    setBuildMethod(state, buildMethod){
      state.buildMethod = buildMethod;
    },
    setDownloadURL(state, downloadURL){
      state.downloadURL = downloadURL;
    },
    setSshPhoneAvailable(state, available){
      state.sshPhoneAvailable = available; 
    },
    setSkipToStep(state, step){
      state.skipToStep = step; 
    }
  },
  actions: {
    logUserIn({ commit }, userData) {
      commit('setLoggedIn', true);
      commit('setUserData', userData);
      commit('setDistributionList', [userData.email])
    },
    startDecryptAndBuild({ commit, state }){
      commit('setBuildState', 'download')

      const { app, buildParams, buildMethod } = state;
      window._uxa.push(['trackPageEvent', `${buildMethod} - ${app.bundleId}:${app.trackName}:${Math.round(app.fileSizeBytes/1e+6)}MB:${buildParams.countryCode}`]);
      heap.track(buildMethod, {
        bundleId: app.bundleId,
        trackName: app.trackName,
        size: `${Math.round(app.fileSizeBytes/1e+6)}MB`,
        countryCode: buildParams.countryCode
      })
    },
    resetBuild({ commit }) {
      commit('resetLogs');
      commit('setBuildState', null);
      commit('setScanResults', null);
      commit('setApp', null);
      commit('setBuildParams', null);
      commit('setDownloadURL', null);
    },
    // SOCKET ACTIONS
    'socket::loading-progress'({ commit }, progress) {
      commit('setProgress', progress);
    },
    'socket::start-install'({ commit }){
      commit('setBuildState', 'install');
    },
    'socket::start-decrypt'({ commit }){
      commit('setBuildState', 'decrypt');
    },
    'socket::start-build'({ commit }){
      commit('setProgress', 0);
      commit('setBuildState', 'building');
    },
    'socket::build-progress'({ commit }, logs){
      commit('addLogs', logs);
    },
    'socket::build-done'({ commit }, buildInfo){
      window._uxa.push(['trackPageEvent', 'Build Done']);
      heap.track('Build Done');

      commit('setBuildState', 'done');
    },
    'socket::scan-results'({ commit }, scanResults){
      commit('setScanResults', scanResults);
    },
    'socket::build-error'({ commit, state }, { message }){
      let errorDetails = {
        user: state.userData.email.split('@')[0],
        buildMethod: state.buildMethod,
        buildState: state.buildState,
        countryCode: state.buildParams.countryCode,
        appName: state.app.trackName.substring(0, 29)
      };
      window._uxa.push(["trackError", message, errorDetails])
      heap.track('Error', Object.assign({message}, errorDetails));
      commit('setBuildState', 'error');
    },
    'socket::show-download-link'({ commit }, downloadURL){
      commit('setDownloadURL', downloadURL);
    },
    'socket::phone-status'({ commit, state }, available){
      commit('setSshPhoneAvailable', available);
      if (available) {
        commit('setBuildMethod', 'build')
        commit('setSshPhoneAvailable', 'available')
      } else {
        commit('setSshPhoneAvailable', false)
        window._uxa.push(["trackError", 'decrypt_device_not_available', {
          user: state.userData.email.split('@')[0]
        }])
        heap.track('Error', {message: 'decrypt_device_not_available', user: state.userData.email.split('@')[0]});
      }
    },
  },
  modules: {
  }
})
