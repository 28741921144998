<template>
  <el-container class="build-container" v-loading="buildState == 'building' && !logs.length">
    <el-header class="build-header">
      <h2>{{downloadURL ? "Download" : "Build Progress"}}</h2>
      <el-button v-if="buildState == 'done'" type="success" @click="_resetBuild">Build Another</el-button>
      <el-button v-if="buildState == 'error'" type="danger" @click="_tryAgain">Try Again</el-button>
    </el-header>
    <el-steps v-if="buildState == 'error'" :active="1" align-center finish-status="error">
      <el-step title="Error"></el-step>
    </el-steps>
    <div v-if="downloadURL">
      <el-link :href="downloadURL"><el-icon :size="100"><Download/></el-icon> </el-link>
      <p>Link will expire in 15 minutes</p>
    </div>
    <el-steps v-else :active="activeStep" align-center finish-status="success">
      <el-step :title="buildState == 'uploading' ? 'Upload': 'Download'" />
      <el-step title="Install" />
      <el-step title="Decrypt" />
      <el-step v-if="buildMethod == 'build'" title="Build" />
      <el-step title="Done" />
    </el-steps>
    <el-progress v-if="buildState == 'uploading'" :text-inside="true" :stroke-width="26" :percentage="progress"></el-progress>
    <div class="logs-container" v-if="!downloadURL">
      <p 
        v-for="(log, index) in logs" 
        :key="index" 
        :ref="index==logs.length-1 ? 'lastLog' : 'log'" 
        class="log">
          {{log}}
      </p>
    </div>
  </el-container>
</template>

<script>
import { defineComponent } from 'vue'
import socketService from '@/services/websocket.service'
import { mapState } from 'vuex'

export default defineComponent ({
  data() {
    return {
      scanResultsColors: [
        {color: '#f56c6c', percentage: 25},
        {color: '#e6a23c', percentage: 50},
        {color: '#5cb87a', percentage: 75},
        {color: '#1989fa', percentage: 100}
      ]
    };
  },
  computed: {
    ...mapState([
      'buildState',
      'progress',
      'logs',
      'buildParams',
      'buildMethod',
      'downloadURL',
      'skipToStep'
    ]),
    activeStep(){
      switch (this.buildState) {
        case 'download':
        case 'uploading':
          return 0
        case 'install':
          return 1
        case 'decrypt':
          return 2
        case 'building':
          return 3
        case 'done':
          return 5
        default:
          return 0;
      }
    }
  },
  watch: {
    logs(newVal){
      const lastLog = this.$refs.lastLog;
      // when ref is used inside v-for it puts it into an array
      if (lastLog && lastLog[0]) lastLog[0].scrollIntoView({ behavior: 'smooth' })
    }
  },
  methods: {
    _onUploadFilesChange(file, fileList) {
      this.fileList = fileList;
    },
    _resetBuild(){
      this.$store.dispatch('resetBuild')
    },
    _tryAgain(){
      this.$store.dispatch('startDecryptAndBuild')
      socketService.io.emit('download-ipa', this.buildParams, this.buildMethod, this.skipToStep);
    }
  }
})
</script>

<style lang="scss">
.el-container.build-container{
  padding: 5px;
  .build-header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .el-steps{
    margin: 20px 0;
  }
  .logs-container{
    max-height: 400px;
    overflow-y: scroll !important;
    padding: 5px;

    .log{
      max-width: 90%;
      text-align: left;
      text-overflow: ellipsis;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
