<template>
  <el-container>
    <el-header>Distribution Emails</el-header>
    <el-main>
      <TagInput :tags='distributionList' :tag-title="'New Email'"/>
    </el-main>
  </el-container>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import {
  TagInput
} from '@/components'

export default defineComponent ({
  components: { TagInput },
  computed: {
    ...mapState(['distributionList'])
  }
})
</script>

<style lang="scss">
.el-header{
}
</style>
